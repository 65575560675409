import { WindowLocation } from "@reach/router"
import Blok from "../interfaces/Storyblok/Blok.interface"
import { StoryblokEntry } from "../templates/storyblok-entry.template"
import { isEditMode } from "../utils/isEditMode.util"
import renderBloks from "../utils/renderBloks.util"
import useStoryblok from "./useStoryblok.hook"

const getContent = (
  storyblokEntry: StoryblokEntry,
  location: WindowLocation
): Blok[] => {
  if (isEditMode()) {
    const {
      content: { body },
    } = useStoryblok(storyblokEntry, location) as any // TODO: Type this correctly as 'content' can be either a string or JSON

    return body
  }

  const { body } = JSON.parse(storyblokEntry.content)

  return body
}

const useDynamicComponent = (
  storyblokEntry: StoryblokEntry,
  location: WindowLocation
): JSX.Element[] => {
  const content = getContent(storyblokEntry, location)
  return renderBloks(content)
}

export default useDynamicComponent
