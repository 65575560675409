import { graphql, PageProps } from "gatsby";
import React from "react";
import Layout from "../components/utils/Layout/Layout.component";
import useDynamicComponent from "../hooks/useDynamicComponent.hook";
import getMetaData from "../utils/getMetaData.util";

export interface StoryblokEntry {
  content: string;
  full_slug: string;
  id: string;
  name: string;
  field_component: any /* TODO */;
  first_published_at: any /* TODO */;
}

interface StoryblokEntryData {
  storyblokEntry: StoryblokEntry;
}

const StoryblokEntryTemplate: React.FC<PageProps<StoryblokEntryData>> = ({
  data,
  location,
}) => {
  const blocks = useDynamicComponent(data.storyblokEntry, location);
  const seo = getMetaData(data?.storyblokEntry.content);

  return <Layout metaData={seo}>{blocks}</Layout>;
};

export const Head: React.FC<PageProps<StoryblokEntryData>> = ({ data }) => {
  const {
    title,
    description,
    og_title,
    og_description,
    og_image,
    twitter_title,
    twitter_description,
    twitter_image,
  } = getMetaData(data?.storyblokEntry.content);

  return (
    <>
      <title>{title ? title : `Tangerine Republic`}</title>
      {description && <meta name="description" content={description} />}
      {og_title && (
        <meta
          property="og:title"
          content={`${og_title} ${og_title && `|`} Tangerine Republic`}
        />
      )}
      <meta property="og:site_name" content="Tangerine Republic" />
      {og_description && (
        <meta property="og:description" content={og_description} />
      )}
      {og_image && <meta property="og:image" content={og_image} />}
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en" />
      {twitter_title && (
        <meta
          name="twitter:title"
          content={`${twitter_title || "Tangerine Republic"}`}
        />
      )}
      {twitter_description && (
        <meta
          name="twitter:description"
          content={`${twitter_description || description}`}
        />
      )}
      {twitter_image && <meta name="twitter:image" content={twitter_image} />}
      <script src="https://unpkg.com/wavesurfer.js"></script>
    </>
  );
};

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id
      name
      full_slug
      content
    }
  }
`;

export default StoryblokEntryTemplate;
